body {
  @apply font-dm;
  margin: 0;
}

a {
  @apply text-blue-900;
  &:hover {
    text-decoration: underline;
  }
}

body h1 {
  @apply font-bold;
  @apply text-3xl;
}

body h2 {
  @apply font-bold;
  @apply text-2xl;
}

body h3 {
  @apply font-bold;
  @apply text-xl;
}

body h4 {
  @apply font-bold;
  @apply text-lg;
}

body h5 {
  @apply font-bold;
  @apply text-base;
}

// START ANCHOUR TYPOGRAPHY UPDATES
.heading {
  @apply font-campton;
  font-style: normal;
  font-weight: 700;
  text-transform: uppercase;
}

.heading-alt {
  @apply font-family;
  font-style: normal;
  font-weight: 500;
}

.tag {
  @apply font-saa_series;
  font-style: normal;
  font-weight: 400;
  text-transform: uppercase;
}

.body {
  @apply font-campton;
  font-style: normal;
  font-weight: 500;
}

.body-alt {
  @apply font-family;
  font-style: normal;
  font-weight: 500;
}

.btn-next {
  &:after {
    content: url("../images/arrow-right2.svg");
    margin-left: 5px;
  }
}

.btn-back {
  &:before {
    content: url("../images/arrow-left2.svg");
    margin-right: 5px;
  }
}

// END ANCHOUR TYPOGRAPHY UPDATES

.sign-up-image {
  @apply w-full;
  @apply h-72;
  @apply rounded-lg;
  @apply object-cover;
  object-position: 0% 25%;

  @media (min-width: 768px) {
    @apply h-full;
    @apply rounded-l-2xl;
    @apply rounded-r-none;
    object-position: 0% 20%;
    position: absolute;
  }

  @media (min-width: 1000px) {
    @apply rounded-2xl;
  }
}

.sign-up-bag-image {
  @apply rounded-xl;
  @apply object-cover;
  height: 24rem;
  width: 100%;

  @media (min-width: 1000px) {
    @apply h-auto;
    @apply rounded-2xl;
    @apply w-full;
    object-position: 12% 0%;
  }
}

.testimonial-container {
  @apply bg-white;
  @apply rounded-xl;
  @apply flex;
  @apply flex-col;
  @apply gap-2.5;
  @apply px-6;
  @apply py-9;

  height: auto !important;
}

.w-walden-responsive {
  @apply lg:block;
  @apply w-full;
  @apply max-w-screen-xl;
}

button.keyboard-navigable:focus-visible {
  @apply ring-4;
  @apply ring-blue-500;
  @apply ring-offset-2;
}

//This class lets you display focus to a parent div when it's child is focussed using a keyboard. Useful when you can't see any focus around a button due to design implementation.
.keyboard-navigable-parent:has(:focus-visible) {
  @apply ring-4;
  @apply ring-blue-500;
  @apply ring-offset-2;
}

.w-walden-responsive-2 {
  max-width: 108rem;
  @apply w-full;
  margin-inline: auto;
}

.social-icon {
  padding: 0.375rem 0.75rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  cursor: pointer;
  text-decoration: none;
  font-weight: 400;
}

.bg-img {
  background-image: var(--bg-image);
}

button:focus {
  outline: 0 !important;
}

input[type="radio"]:checked ~ .label-checked {
  border-color: #ff6900;
  background-color: #f8f9fa;
}

input[type="radio"]:checked ~ .size-label-container {
  @apply bg-walden-highlight-green;
  @apply text-black;
}

input[type="radio"] ~ .size-label-container {
  max-width: 188px;
}

input[type="radio"]:checked
  ~ .size-label-container
  .size-label-image-container {
  @apply border-2;
  @apply border-walden-medium-green;
}

input[type="radio"]:checked
  ~ .size-label-container
  .size-label-image-container
  span {
  @apply block;
}

.sign-up-swipper-btn.swiper-button-prev {
  @apply text-walden-medium-green;
  @apply bottom-0;
  @apply left-8;

  top: unset;

  &:after {
    content: url("../images/arrow-left.svg");
    @apply text-walden-medium-green;
  }
}

.sign-up-swipper-btn.swiper-button-next {
  @apply text-walden-medium-green;
  @apply bottom-0;
  @apply right-8;

  top: unset;

  &:after {
    content: url("../images/arrow-right.svg");
    @apply text-walden-medium-green;
  }
}

.button {
  background-color: #fe6900;
  color: #fffdfc;
  padding: 7px 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    @apply bg-walden-dark-orange;
  }

  &.disabled {
    @apply bg-walden-gray-dark;
  }

  &:disabled {
    @apply cursor-not-allowed;
    @apply bg-walden-gray-dark;
  }
}

.primary_button {
  @apply bg-walden-secondary-lush;
  @apply text-walden-primary-off-white;
  padding: 7px 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    @apply bg-walden-primary-dark-forest;
  }

  &.disabled {
    @apply cursor-not-allowed;
    @apply bg-walden-secondary-hay;
    @apply bg-opacity-25;
    @apply text-walden-secondary-hay;
  }

  &:disabled {
    @apply cursor-not-allowed;
    @apply bg-walden-secondary-hay;
    @apply bg-opacity-25;
    @apply text-walden-secondary-hay;
  }
}

.copy-button {
  @apply px-4;
  @apply py-2;
  @apply rounded-md;

  text-decoration: none;
  border: 1px solid #d1d5dbb8;
  background-color: #ff9400;
  color: #fffdfc;
  cursor: pointer;

  &:active {
    @apply opacity-50;
  }
}

.center-x {
  left: 50%;
  transform: translateX(-50%);
}

.product-container .product-description {
  height: 0px;
  color: rgba(0, 0, 0, 0);
  transition: height 0.25s ease-out;
  -webkit-transition: height 0.25s ease-out;
  -moz-transition: height 0.25s ease-out;
  -o-transition: height 0.25s ease-out;
  a {
    display: none;
  }
}

.product-container:hover .product-description {
  @apply px-6;
  @apply pt-6;
  display: block;
  height: 100%;
  color: #fff;
  a {
    display: block;
    @apply text-blue-200;
  }
}

// NEW HEADINGS

h1.heading-xl {
  @apply text-4.5xl md:text-7xl;
  @apply font-extrabold;
}

h2.heading-lg {
  @apply text-4xl md:text-6xl;
  @apply font-extrabold;
}

h3.heading-md {
  @apply text-3xl md:text-5xl;
  @apply font-extrabold;
}

h4.heading-sm {
  @apply text-2xl md:text-4xl;
  @apply font-extrabold;
}

h5.heading-xs {
  @apply text-lg md:text-2xl;
  @apply font-extrabold;
}

h6.heading-2xs {
  @apply text-base md:text-lg;
  @apply font-extrabold;

  letter-spacing: -0.03375rem;
}

.heading-3xs {
  font-size: 14px;
  font-style: normal;
  font-weight: 800;
  line-height: 22px; /* 157.143% */
  letter-spacing: -0.28px;

  @media (min-width: 768px) {
    font-size: 16px;
    font-style: normal;
    font-weight: 800;
    line-height: 26px; /* 162.5% */
    letter-spacing: -0.48px;
  }
}

.password-requirement {
  @apply text-walden-medium-green;

  &.invalid {
    @apply text-walden-black-tint-01;
  }
}
