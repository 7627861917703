.email-signup-container {
  @media (min-width: 1024px) {
    max-width: 496px;
    width: 100%;
  }
}

.b-corp {
  height: 112px;
  width: 76px;
}

.klaviyo-form-STWjHT {
  @apply mb-8 md:mb-0;

  h1 {
    text-transform: uppercase;
  }

  p {
    @apply text-walden-primary-off-white;
  }

  [data-testid="form-row"] {
    @apply flex-wrap;

    gap: 12px;

    > div {
      padding: 0 !important;
      width: 100% !important;

      @screen lg {
        width: unset !important;
      }
    }
  }

  button {
    @apply uppercase w-full;

    height: unset !important;
    min-height: 58px;
    padding: 0 34px !important;
    transition: 0.2s ease-in-out;

    &:hover,
    &:focus {
      @apply bg-walden-secondary-hay #{!important};

      transition: 0.2s ease-in-out;
    }
  }
}
