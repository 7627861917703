@use "colors";

.label-standard {
  @apply text-xs;
  @apply font-bold;
  @apply uppercase;
}

.input-standard {
  @apply appearance-none;
  @apply block;
  @apply w-full;
  @apply bg-white;
  @apply border;
  @apply rounded;
  @apply py-3;
  @apply px-4;
  @apply mb-3;
}

.input-standard-new {
  @apply text-base;
  @apply appearance-none;
  @apply block;
  @apply bg-white;
  @apply border;
  @apply rounded-lg;
  @apply py-2.5;
  @apply px-6;
}

.input-standard-new::placeholder {
  @apply text-walden-black-tint-01;
}

.input-registration-new {
  @apply text-base;
  @apply appearance-none;
  @apply block;
  @apply bg-white;
  @apply border;
  @apply rounded-lg;
  @apply py-3;
  @apply px-4;
  @apply mb-3;
}

.input-registration-new::placeholder {
  @apply text-walden-black-tint-01;
}

.select-standard-new {
  @apply text-base;
  @apply block;
  @apply bg-white;
  @apply border;
  @apply rounded-lg;
  @apply p-4;
  @apply mb-4;
  &:invalid {
    @apply text-walden-black-tint-01;
  }
}

.select-standard-new::placeholder {
  @apply text-walden-black-tint-01;
}

/* Style the submit button */
.submit-button-standard {
  @apply min-w-min;
  @apply bg-walden-orange;
  @apply text-white;
  @apply p-2;
  @apply rounded;
  @apply border-none;
  @apply cursor-pointer;

  &:enabled:hover {
    @apply bg-walden-dark-orange;
  }

  &:disabled {
    opacity: 0.65;
    cursor: not-allowed;
  }
}

.button-primary {
  @apply rounded-lg;
  @apply bg-walden-dark-green;
  @apply flex;
  @apply w-full;
  @apply justify-center;
  @apply items-center;
  @apply gap-3;
  @apply text-white;
  @apply text-center;
  @apply text-lg;
  @apply font-bold;
  @apply m-auto;
  @apply cursor-pointer;
  @apply h-14;
  letter-spacing: -0.0225rem;

  &:hover,
  &:active {
    @apply bg-walden-medium-green;
  }

  &:disabled {
    @apply cursor-not-allowed;
    @apply bg-walden-light-gray;
    @apply text-walden-black-tint-01;
  }
}

.button-text-small {
  @apply text-base;
  @apply leading-4;
  letter-spacing: -0.02rem;
}

.icon-arrow {
  @apply h-3.5;
}

.icon-arrow:before {
  content: url("../images/arrow-1.svg");
}

.link {
  @apply text-walden-black;
  @apply text-base;
  @apply font-bold;
  @apply underline;
  text-underline-position: under;
  @apply leading-5;
  letter-spacing: -0.02rem;

  &:hover,
  &:active {
    @apply text-walden-medium-green;
  }

  &:disabled {
    @apply cursor-not-allowed;
    @apply text-walden-black-tint-02;
  }
}

.link-text-small {
  @apply text-sm;
  @apply leading-5;
  letter-spacing: -0.0175rem;
}

/* .show-if-checked-container is a pattern for showing/hiding
   an element, if the checkbox inside of it has been selected.
   It also features the user clicking on the element to uncheck
   it */

.show-if-checked-container input[type="checkbox"] {
  display: none;
}
.show-if-checked-container input:not(:checked) ~ .show-if-checked-main-div {
  display: none;
}

.show-if-checked-container input:checked ~ .show-if-checked-main-div:hover i {
  @apply text-red-500;
}

/* Customize the container class the checkbox sits in */
.checkbox-container {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkbox {
  position: absolute;
  height: 25px;
  width: 25px;
  background-color: #eee;
  border-radius: 2px;
}

.checkbox-pull-right .checkbox {
  right: 0;
}

/* Center the checkbox inside the checkbox-container class */
.checkbox-center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* On mouse-over, add a darker grey background color */
.checkbox-container:hover input ~ .block .checkbox {
  background-color: colors.$walden-gray-2x-light;
}

.checkbox-hover-red.checkbox-container:hover input ~ .block .checkbox {
  background-color: colors.$red-alert;
}
.checkbox-hover-red.checkbox-container:hover
  input:not(:checked)
  ~ .checkbox-with-border {
  border-color: colors.$red-alert;
}

/* When the checkbox disabled add faded walden-orange */
.checkbox-container input[disabled]:checked ~ .block .checkbox {
  opacity: 0.4;
}

/* When the checkbox is checked, add walden-orange */
.checkbox-container input:checked ~ .block .checkbox {
  background-color: colors.$walden-orange;
}

/* When the checkbox is not checked, apply a walden-gray-light   image filter */
.checkbox-container input:not(:checked) ~ .meat-type-img {
  fill: colors.$walden-gray-light;
}

.checkbox-container input:disabled ~ .meat-type-img {
  fill: colors.$walden-gray-light !important;
}

/* When the checkbox is checked, apply a walden-green image filter */
.checkbox-container input:checked ~ .meat-type-img {
  fill: colors.$walden-green;
}

/* When the checkbox is not checked, apply a walden-gray-light   border color */
.checkbox-container input:not(:checked) ~ .checkbox-with-border {
  border-color: colors.$walden-gray-light;
}

/* When the checkbox is checked, apply a walden-green border color */
.checkbox-container input:checked ~ .checkbox-with-border {
  border-color: colors.$walden-orange;
}

/* Add a checkmark inside of the checkbox (hidden when not checked) */
.checkbox:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkbox-container input:checked ~ .block .checkbox:after {
  display: block;
}

/* Style the checkmark and position within the checkbox */
.checkbox-container .checkbox.checkbox-tick:after {
  top: 5px;
  left: 9px;
  width: 8px;
  height: 12px;
  border: solid colors.$white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.checkbox-container.checkbox-red input:checked ~ .block .checkbox {
  background-color: colors.$red-alert;
}

.checkbox-container.checkbox-red input:checked ~ .block {
  text-decoration: line-through;
}

/* Styling the radio boxed options container */
.radio-container input[type="radio"] {
  display: none;
}

.radio-container .radio-checkbox {
  height: 25px;
  width: 25px;
  background-color: #eee;
  border-radius: 2px;
  position: relative;
}

.radio-container .radio-checkbox:after {
  height: 25px;
  width: 25px;
  background-color: #eee;
  border-radius: 2px;
  position: absolute;
  left: 0;
  top: 0;
  content: "";
}

.radio-container .radio-checkbox .radio-checkbox-tick:after {
  top: 4px;
  left: 8px;
  width: 8px;
  height: 13px;
  border: solid colors.$white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  content: "";
  position: absolute;
}

.radio-container input[type="radio"]:checked + label .radio-checkbox {
  border: 1px solid colors.$walden-orange;
  background-color: colors.$walden-orange;
}

.radio-container input[type="radio"]:checked + label .radio-checkbox:after {
  display: none;
}

.tooltip {
  display: none;
  left: 50%;
  bottom: 120%;
  transform: translateX(-50%);
}

.tooltip-container:hover .tooltip {
  display: block;
}

.tooltip-caret {
  left: 50%;
  transform: translateX(-50%) rotate(45deg);
  width: 10px;
  height: 10px;
  bottom: -5px;
}

.perma-tooltip {
  display: none;
  left: 50%;
  bottom: 120%;
  transform: translateX(-50%);
}

.perma-tooltip-container:hover .perma-tooltip {
  display: block;
}

.perma-tooltip-caret {
  left: 50%;
  transform: translateX(-50%) rotate(45deg);
  width: 10px;
  height: 10px;
  bottom: -5px;
}

.disabled-option {
  cursor: not-allowed;
  display: relative;
  opacity: 0.2;
  pointer-events: none;
}
