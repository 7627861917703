@use "colors";

/* Styling the radio boxed options container */
.radio-container-v2 input[type="radio"] {
  display: none;
}

.radio-container-v2 .radio-checkbox-v2 {
  height: 20px;
  width: 20px;
  background-color: colors.$white;
  position: relative;
  @apply rounded-full;
}

.radio-container-v2 .radio-checkbox-v2:after {
  height: 20px;
  width: 20px;
  background-color: colors.$white;
  position: absolute;
  left: 0;
  top: 0;
  content: "";
  @apply rounded-full;
  @apply border;
  @apply border-walden-medium-green;
}

// bg-walden-green rounded border border-walden-green text-white
// bg-walden-highlight-green rounded border border-walden-medium-green text-walden-medium-green
.shellfish-selector {
  @apply bg-white;
  @apply text-black;
  @apply border-walden-black-tint-04;
  @apply border;
}

.radio-container-v2 .radio-checkbox-v2 .radio-checkbox-tick-v2:after {
  top: 4px;
  left: 8px;
  width: 6px;
  height: 8px;
  border: solid colors.$white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  content: "";
  position: absolute;
}

.radio-container-v2 input[type="radio"]:checked + label .radio-checkbox-v2 {
  border: 3px solid colors.$white;
  background-color: colors.$walden-medium-green;
  outline: 1px solid colors.$walden-medium-green;
}

.radio-container-v2 input[type="radio"]:checked + label .shellfish-selector {
  @apply bg-walden-highlight-green;
  @apply text-walden-medium-green;
  @apply border-2;
  @apply border-walden-medium-green;
  @apply font-bold;
}

.radio-container-v2
  input[type="radio"]:checked
  + label
  .radio-checkbox-v2:after {
  display: none;
}
