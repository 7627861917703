// Sign Up Breadcrumb
.breadcrumb-body {
  @apply flex;
  @apply justify-center;
  @apply rounded-full;
  @apply bg-walden-mint-green;
}

.breadcrumb-item {
  @apply w-32;
  @apply md:w-40;
  @apply py-2;
  @apply px-4;
  @apply md:py-3;
  @apply flex;
  @apply justify-center;
  @apply items-center;
  @apply inline-flex;
  @apply font-medium;
  @apply text-walden-medium-green;
  @apply text-xs;
  @apply md:text-base;
  letter-spacing: -0.12px;
}

.breadcrumb-item.active {
  @apply bg-walden-dark-green;
  @apply md:w-48;
  @apply rounded-full;
  @apply font-bold;
  @apply text-white;
}

.custom-share-error {
  @apply bg-walden-error-pink;
  @apply flex;
  @apply rounded-full;
  @apply items-center;
  @apply justify-center;
}
