.promo-banner {
  @apply flex;
  @apply flex-col;
  @apply relative;
  @apply bg-walden-beige;
  @apply px-10;
  @apply py-3;
  @apply leading-normal;
  @apply mb-auto;
  @apply text-center;
  @apply text-walden-dark-green;
}

@media (max-width: 640px) {
  .promo-banner {
    @apply px-2.5;
    @apply h-auto;
    @apply py-1.5;

    flex-grow: 0;
  }
}

@media (max-width: 991px) {
  .promo-banner {
    @apply my-auto;
    @apply text-center;
  }
}
