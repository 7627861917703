@font-face {
  font-family: "Futura LT W01 Book";
  src: url("../fonts/cf053eae-ba1f-44f3-940c-a34b68ccbbdf.eot?#iefix");
  src:
    url("../fonts/cf053eae-ba1f-44f3-940c-a34b68ccbbdf.eot?#iefix")
      format("eot"),
    url("../fonts/8bf38806-3423-4080-b38f-d08542f7e4ac.woff2") format("woff2"),
    url("../fonts/e2b9cbeb-fa8e-41cd-8a6a-46044b29ba52.woff") format("woff"),
    url("../fonts/c2a69697-4f06-4764-abd4-625031a84e31.ttf") format("truetype"),
    url("../fonts/dc423cc1-bf86-415c-bc7d-ad7dde416a34.svg#dc423cc1-bf86-415c-bc7d-ad7dde416a34")
      format("svg");
  font-display: swap;
}

@font-face {
  font-family: "Futura LT W01 Medium";
  src: url("../fonts/0a41510f-c527-4001-89c5-d66d2c9ed34b.eot?#iefix");
  src:
    url("../fonts/0a41510f-c527-4001-89c5-d66d2c9ed34b.eot?#iefix")
      format("eot"),
    url("../fonts/e17c7943-8db2-4470-9e2a-74a8d01f6776.woff2") format("woff2"),
    url("../fonts/6c72b0cb-89a1-4da9-8574-9244967bd9d6.woff") format("woff"),
    url("../fonts/dd79c75a-b0c9-4d70-a5a7-bdde1d5449fd.ttf") format("truetype"),
    url("../fonts/c35c8eec-24d3-4ed7-9159-df80a008bf54.svg#c35c8eec-24d3-4ed7-9159-df80a008bf54")
      format("svg");
  font-display: swap;
}

@font-face {
  font-family: "Futura LT W01 Bold";
  src: url("../fonts/87f56fc1-ab05-4355-90af-9e83740663af.eot?#iefix");
  src:
    url("../fonts/87f56fc1-ab05-4355-90af-9e83740663af.eot?#iefix")
      format("eot"),
    url("../fonts/3cf9e1e8-63a2-497f-86ed-6b63d6de1986.woff2") format("woff2"),
    url("../fonts/184895ac-545b-450b-9d3a-c0a0a7620977.woff") format("woff"),
    url("../fonts/bf8628d2-1ae0-4645-ba3f-3ce62d8d907c.ttf") format("truetype"),
    url("../fonts/20073de8-56dd-4e4c-9434-5097c3f2fd28.svg#20073de8-56dd-4e4c-9434-5097c3f2fd28")
      format("svg");
  font-display: swap;
}

@font-face {
  font-family: "Futura LT W01 Heavy";
  src: url("../fonts/752e3189-96d5-4999-8ae7-c435a470461b.eot?#iefix");
  src:
    url("../fonts/752e3189-96d5-4999-8ae7-c435a470461b.eot?#iefix")
      format("eot"),
    url("../fonts/f3fa9288-dc04-4f75-b949-b06584863c9c.woff2") format("woff2"),
    url("../fonts/6b1bbd38-7556-4809-a151-88182a37116e.woff") format("woff"),
    url("../fonts/1f56887e-52d4-4229-b219-513cbef3ff58.ttf") format("truetype"),
    url("../fonts/33342cd1-a4fd-46e1-a810-aee3d1c24099.svg#33342cd1-a4fd-46e1-a810-aee3d1c24099")
      format("svg");
  font-display: swap;
}

@font-face {
  font-family: "RB Campton Neue";
  font-weight: 700;
  src: url("../fonts/NeueCampton-Bold.otf");
  src:
    url("../fonts/NeueCampton-Bold.otf") format("otf"),
    url("../fonts/NeueCampton-Bold.woff2") format("woff2");
  font-display: swap;
}

@font-face {
  font-family: "RB Campton Neue";
  font-weight: 500;
  src: url("../fonts/NeueCampton-Medium.otf");
  src:
    url("../fonts/NeueCampton-Medium.otf") format("otf"),
    url("../fonts/NeueCampton-Medium.woff2") format("woff2"),
    url("../fonts/NeueCampton-Medium.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: "Family Medium";
  font-weight: 500;
  src: url("../fonts/family-medium.otf");
  src:
    url("../fonts/family-medium.otf") format("otf"),
    url("../fonts/family-medium.woff2") format("woff2");
  font-display: swap;
}

@font-face {
  font-family: "Saa Series";
  font-weight: 400;
  src: url("../fonts/Saa-Series-F-D.otf");
  font-display: swap;
}
