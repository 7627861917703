$white: #ffffff;
$red-alert: #ff0000;
$walden-green: #183028;
$walden-green-faint: #646b64;
$walden-green-light: #aebbbd;
$walden-green-medium: #2f443e;
$walden-green-dark: #212529;
$walden-red: #ff4545;
$walden-danger-red: #e74d3c;
$walden-orange: #ff6900;
$walden-dark-orange: #d95900;
$walden-gray-light: #c5cbc9;
$walden-gray-medium: #6c757d;
$walden-gray-dark: #495057;
$walden-gray-x-light: #e5e7eb;
$walden-gray-2x-light: #ccc;
$walden-gray-3x-light: #f8f9fa;
$walden-blue-faint: #bac9ce;
$walden-dark-green: #1e2f28;
$walden-medium-green: #227055;
$walden-bright-green: #aeeec3;
$walden-mint-green: #c5edd3;
$walden-highlight-green: #effdf2;
$walden-error-red: #c30510;
$walden-error-pink: #ffd5cf;
$walden-orange-new: #ff6800;
$walden-orange-tint: #fff0e5;
$walden-beige: #f7f7f0;
$walden-cream: #f8f0e1;
$walden-lightest-gray: #f2f2f2;
$walden-light-gray: #dedede;
$walden-black-tint-04: #c3c3c3;
$walden-black-tint-03: #a0a0a1;
$walden-black-tint-02: #888888;
$walden-black-tint-01: #676767;
$walden-black: #121213;
$walden-primary-dark-forest: #1c3a2b;
$walden-primary-dark-forest-01: #334e40;
$walden-primary-dark-forest-02: #5c7166;
$walden-primary-dark-forest-03: #8d9c95;
$walden-primary-dark-forest-04: #c2cac5;
$walden-primary-dark-forest-05: #eff1f0;
$walden-primary-off-white: #f9f6e8;
$walden-primary-sunrise: #e3c967;
$walden-primary-almost-black: #0e1e16;
$walden-primary-white: #ffffff;
$walden-secondary-lush: #226144;
$walden-secondary-grass: #5f9457;
$walden-secondary-grass-compliant: #54814b;
$walden-secondary-lighter-grass: #7ea776;
$walden-secondary-fern: #b6d3bb;
$walden-secondary-soil: #826a52;
$walden-secondary-hay: #c3b182;
$walden-secondary-cream: #f3edd3;
$walden-tertiary-sky-blue: #75bce9;
$walden-tertiary-ocean: #2354c0;
$walden-tertiary-red: #df5638;
$walden-tertiary-red-compliant: #d34322;
$walden-tertiary-lighter-red: #e7816a;
