// Walden CSS classes which use Tailwind @apply syntax

.search-form {
  @apply flex;
  @apply flex-row;
  @apply max-w-lg;
  @apply w-auto;
  @apply border;
  @apply border-walden-gray-light;
  @apply rounded-sm;
  @apply my-3;
  @apply border-collapse;

  /* Style the submit button */
  input[type="submit"] {
    @apply border;
    @apply border-walden-orange;
    @apply cursor-pointer;
    @apply rounded-none;

    @apply hover:bg-walden-dark-orange;

    &:disabled {
      opacity: 0.65;
      cursor: not-allowed;
    }
  }
}

.admin-link {
  @apply text-blue-900;
  text-decoration: underline;

  &:hover {
    text-decoration: underline;
  }
}

.admin_table {
  @apply border-collapse;
  @apply w-full;

  > tbody > tr > td,
  > thead > tr > th {
    border: 1px solid #ccc;
    padding: 3px;
  }

  a {
    @extend .admin-link;
  }

  @media only screen and (max-width: 1000px) {
    /* Force table to not be like tables anymore */
    > tbody > tr {
      border: 1px solid #ccc;
      padding: 3px;
    }

    > thead > th,
    > tbody > tr > td {
      display: block;
    }

    table {
      border-collapse: collapse;
    }

    /* Hide table headers (but not display: none;, for accessibility) */
    > thead > tr {
      position: absolute;
      top: -9999px;
      left: -9999px;
    }

    > tbody > tr > td {
      min-height: 30px;
      width: 100%;
      border: none;
      border-bottom: 1px solid #eee;
      padding-left: 40%;
      white-space: normal;
      text-align: left;
      overflow-x: scroll;
      position: relative;
      &:before {
        /* Now like a table header */
        position: absolute;
        /* Top/left values mimic padding */
        top: 3px;
        left: 6px;
        width: 35%;
        padding-right: 3px;
        text-align: left;
        font-weight: bold;
        /* Label the data */
        content: attr(data-title);
      }
    }
  }
}

/* Styled selects */
.member-select {
  &.widthmax {
    @apply w-max;
  }
  @apply w-full;
  @apply rounded;
  @apply border;
  @apply border-walden-gray-light;
  @apply outline-none;
  padding: 5px !important;
  background: url(../images/walden-down-arrow-c.png) no-repeat 95% 57% #ffffff !important;
  background-size: 1.5em !important;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.add-on-card {
  @apply px-6 py-5 gap-6 inline-flex relative bg-white border-white border-2 rounded-lg;
}

.add-on-card.selected-add-on {
  @apply rounded;
  @apply border;
  @apply border-walden-medium-green;
  border-width: 2px;
  border-radius: 0.5rem;
}

.add-on-card .checkmark {
  visibility: hidden;
}

.add-on-card.selected-add-on .checkmark {
  visibility: visible;
}

.add-on-card .plus {
  @apply text-white;
}

.add-on-card.selected-add-on .plus {
  @apply text-walden-medium-green;
}

.add-on-card.selected-add-on .plus.disabled {
  @apply text-walden-light-gray;
  cursor: not-allowed;
}

.plan-content-container {
  @apply w-90vw;
  @apply md:w-screen-md;
  @apply lg:w-screen-lg;
  @apply xl:w-80vw;
  @apply lg:max-w-screen-xl;

  @apply m-auto;
  @apply md:py-16;
  @apply py-16;

  @apply md:px-0;
  @apply sm:px-12;
}

.plan-content-heading {
  @apply text-3xl;
  @apply uppercase;
  @apply font-bold;
}

.plan-content-description {
  @apply xs:text-xl;
  @apply text-lg;
  @apply font-bold;
  @apply text-center;
}

.flash {
  @apply mx-auto;

  @apply text-center;
  @apply text-white;

  @apply py-6;
}

.notice {
  @apply bg-green-600;
}

.alert {
  @apply bg-walden-tertiary-red-compliant;
}

.category-tab-unselected {
  background-color: #eeeeee;
  a {
    @apply text-walden-green-dark;
  }
}

.category-tab-selected {
  @apply bg-walden-gray-medium;
  a {
    @apply text-white;
  }
}

// Member form
.edit-button {
  @apply inline-block;
  @apply bg-walden-orange;
  @apply text-white;
  @apply py-2;
  @apply px-4;
  @apply rounded;
  @apply uppercase;
  @apply font-bold;
}
