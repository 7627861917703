:root {
  --size: 100%;
  --clr1: #183028; // $walden-green
  --clr2: #aebbbd; // $walden-green-light
  --clr3: #183028; // $walden-green
  --clr4: #aebbbd; // $walden-green-light
  --clr5: #183028; // $walden-green
}

@keyframes spinner {
  0% {
    transform: translate3d(-50%, -50%, 0) rotate(0deg);
  }
  100% {
    transform: translate3d(-50%, -50%, 0) rotate(360deg);
  }
}

turbo-frame.disabled-while-loading[busy] {
  * {
    pointer-events: none;
    opacity: 0.9;
  }
}

turbo-frame.spinner-while-loading[busy] {
  height: 50px;
  width: 100%;
  display: inline-block;
  position: relative;
  transition: opacity linear 0.1s;
  * {
    opacity: 0;
    visibility: hidden;
    border: none;
  }
  &::before {
    animation: 2s linear infinite spinner;
    border: solid 3px #eee;
    border-bottom-color: #183028; // $walden-green;
    border-radius: 50%;
    content: "";
    height: 40px;
    left: 50%;
    opacity: inherit;
    position: absolute;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);
    transform-origin: center;
    width: 40px;
    will-change: transform;
  }
  &.with-margin-while-loading::before {
    margin-top: 100px;
  }
}

.spinner:nth-child(1) {
  --animation-duration: 1000ms;

  width: var(--size);
  height: var(--size);
  display: flex;
  align-items: center;
  justify-content: space-evenly;

  .spinner-item {
    width: calc(var(--size) / 12);
    height: 80%;
    background: var(--clr-spinner);
    animation: spinner1 var(--animation-duration) ease-in-out infinite;

    @keyframes spinner1 {
      50% {
        transform: scaleY(0.25);
      }
    }
  }

  .spinner-item:nth-child(1) {
    --clr-spinner: var(--clr1);
    animation-delay: calc(var(--animation-duration) / 10 * -3);
  }

  .spinner-item:nth-child(2) {
    --clr-spinner: var(--clr2);
    animation-delay: calc(var(--animation-duration) / 10 * -1);
  }

  .spinner-item:nth-child(3) {
    --clr-spinner: var(--clr3);
    animation-delay: calc(var(--animation-duration) / 10 * -2);
  }

  .spinner-item:nth-child(4) {
    --clr-spinner: var(--clr4);
    animation-delay: calc(var(--animation-duration) / 10 * -1);
  }

  .spinner-item:nth-child(5) {
    --clr-spinner: var(--clr5);
    animation-delay: calc(var(--animation-duration) / 10 * -3);
  }
}
