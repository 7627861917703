@use "colors";

.share-card ul {
  list-style-type: disc !important;
}

.share-card-wrapper,
.share-card-wrapper-v2 {
  height: auto !important;
}

.selected {
  border-color: #ff6900 !important;
}

.share-card-wrapper-v2.selected-v2 .share-card {
  @apply border-walden-medium-green;
  @apply border-2;
  @apply bg-walden-highlight-green;
}

.share-card-wrapper-v2 .share-type-img-circle {
  @apply border-walden-black-tint-04;
}

.share-card-wrapper-v2.selected-v2 .share-card .share-type-img-circle {
  @apply border-walden-medium-green;
}

.share-card-wrapper-v2 .share-type-img {
  fill: colors.$walden-black-tint-04;
}

.share-type-img {
  fill: colors.$walden-black;
}

.share-card-wrapper-v2.selected-v2 .share-card .share-type-img {
  fill: colors.$walden-medium-green;
}

.share-card-wrapper-v2 .share-card .selected-checkbox {
  @apply hidden;
}

.share-card-wrapper-v2.selected-v2 .share-card .selected-checkbox {
  @apply block;
}

.share-card-wrapper-v2 .custom-share-type-selected-message {
  @apply hidden;
}

.share-card-wrapper-v2.selected-v2
  .share-card
  ~ .custom-share-type-selected-message {
  @apply block;
}

.questionnaire-container {
  @apply flex;
  @apply flex-col;
  @apply text-center;
  @apply gap-6;
  @apply m-auto;
  @apply max-w-screen-xl;
  @media (min-width: 768px) {
    @apply flex-row;
    @apply gap-24;
  }
}

.questionnaire-container .questionnaire-option {
  @apply border;
  @apply rounded-lg;
  @apply border-walden-black-tint-04;
  @apply bg-white;
  @apply text-walden-medium-green;
  @apply font-medium;
  @apply cursor-pointer;
  @apply w-full;
  @apply py-2.5;
  @apply text-base;
  @media (min-width: 768px) {
    @apply text-xl;
    @apply py-4;
    @apply rounded-2xl;
  }
}

.questionnaire-container .questionnaire-option.selected-v2 {
  @apply border-2;
  @apply border-walden-medium-green;
  @apply bg-walden-highlight-green;
  @apply font-black;
}
