header.members-header {
  .main-nav-link {
    @apply text-walden-primary-off-white;
    @apply leading-7;
    @apply border-b-2;
    @apply border-transparent;
    &:hover {
      @apply text-walden-primary-sunrise;
      @apply border-walden-primary-sunrise;
      @apply no-underline;
    }
    &.active {
      @apply text-walden-primary-sunrise;
      @apply border-walden-primary-sunrise;
    }
  }

  .main-nav-link-reserve {
    @apply text-walden-primary-almost-black;
    @apply leading-7;
    @apply border-b-2;
    @apply border-transparent;
    &:hover {
      @apply text-walden-primary-dark-forest;
      @apply border-walden-primary-dark-forest;
      @apply no-underline;
    }
    &.active {
      @apply text-walden-primary-dark-forest;
      @apply border-walden-primary-dark-forest;
    }
  }

  .sub-nav-link {
    &.active {
      @apply text-white;
      @apply bg-walden-orange;

      &:hover {
        @apply text-white;
        @apply bg-walden-dark-orange;
      }
    }
  }
}

header.admin-nav {
  .main-nav-link {
    &.active {
      @apply text-white;
    }
  }
}
