@use "colors";

/* Customize the container class the checkbox sits in */
.checkbox-container-v2 {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.checkbox-container-v2 input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkbox-v2 {
  position: absolute;
  height: 18px;
  width: 18px;
  @apply flex;
  @apply bg-white;
  @apply rounded-full;
  @apply items-center;
  @apply justify-center;

  @media (min-width: 1210px) {
    height: 25px;
    width: 25px;
  }
}

.checkbox-v2.checkbox-square {
  @apply rounded-md;
  @apply border-walden-medium-green;
  @apply border-2;
}

.checkbox-pull-right-v2 .checkbox-v2 {
  right: 0;
}

/* Center the checkbox inside the checkbox-container class */
.checkbox-center-v2 {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.checkbox-container-v2 input[disabled]:checked ~ .block .checkbox-v2 {
  opacity: 0.4;
}

/* When the checkbox is checked, add walden-medium-green */
.checkbox-container-v2 input:checked ~ .block .checkbox-v2 {
  @apply bg-walden-medium-green;
}

.checkbox-container-v2 input:checked ~ .block .checkbox-v2-text {
  @apply text-walden-medium-green;
  @apply font-bold;
}

.checkbox-container-v2 input:checked ~ .meat-type-label-name {
  @apply font-bold;
}

// todo change colour of $10
.checkbox-container-v2 input:checked ~ .premium-price-notice {
  @apply bg-walden-medium-green;
}

.checkbox-option-card:has(
    .checkbox-container-v2 input:checked ~ .block .checkbox-v2
  ) {
  @apply bg-walden-highlight-green;
  @apply border-walden-medium-green;
  @apply border-2;
  @apply mb-0;
}

.checkbox-option-card.vertical_aligned:has(
    .checkbox-container-v2 input:not(:checked)
  ) {
  @apply mb-0.5;
}

.checkbox-option-card:has(.checkbox-container-v2 input:not(:checked)) {
  @apply bg-white;
  @apply border-walden-light-gray;
  @apply border;
  @apply text-base;
}

.checkbox-v2-text {
  @apply pl-9;
  @apply md:pl-12;
}

/* When the checkbox is not checked, apply a black-tint-04 image filter */
.checkbox-container-v2 input:not(:checked) ~ div .meat-type-img {
  fill: colors.$walden-black-tint-04;
}

.checkbox-container-v2 input:disabled ~ div .meat-type-img {
  fill: colors.$white !important;
}

/* When the checkbox is checked, apply a walden-dark-green image filter */
.checkbox-container-v2 input:checked ~ div .meat-type-img {
  fill: colors.$walden-dark-green;
}

/* When the checkbox is not checked, apply a white border color */
.checkbox-container-v2 input:not(:checked) ~ .checkbox-with-border {
  border-color: colors.$white;
}

/* When the checkbox is checked, apply a walden-green border color */
.checkbox-container-v2 input:checked ~ .checkbox-with-border {
  border-color: colors.$walden-medium-green;
}

/* Add a checkmark inside of the checkbox (hidden when not checked) */
.checkbox-v2:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkbox-container-v2 input:checked ~ .block .checkbox-v2:after {
  display: block;
}

/* Style the checkmark and position within the checkbox */
.checkbox-container-v2 .checkbox-v2.checkbox-tick-v2:after {
  top: 20%;
  width: 6px;
  height: 8px;
  border: solid colors.$white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(35deg);
  -ms-transform: rotate(35deg);
  transform: rotate(35deg);

  @media (min-width: 1210px) {
    border-width: 0 3px 3px 0;
    width: 8px;
    height: 12px;
  }
}

.checkbox-container-v2.checkbox-red input:checked ~ .block .checkbox-v2 {
  background-color: colors.$red-alert;
}

.checkbox-container-v2.checkbox-red input:checked ~ .block {
  text-decoration: line-through;
}
